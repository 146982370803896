import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageIcon from '@mui/icons-material/Image';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import QuizIcon from '@mui/icons-material/Quiz';
import SecurityIcon from '@mui/icons-material/Security';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Collapse, List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAdminAuthorized } from 'csam/api/api';
import PieChartIcon from '@mui/icons-material/PieChart';
interface Page {
  id: string;
  name: string;
  icon: JSX.Element;
  route?: string;
  subpages?: { id: string; name: string; route: string }[];
}

const MainListItems: React.FC = () => {
  let pages: Page[];

  const { isAdmin, isLoading } = useAdminAuthorized();
  // if (isLoading) {
  //   return <AdminPageLoader />;
  // }
  console.log('isAdmin', isAdmin);
  if (isAdmin && isAdmin === 5) {
    pages = [
      {
        id: 'dataProtection',
        name: 'Data Protection',
        icon: <SecurityIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'dataProTips', name: 'Data Proctection Tips', route: '/admin/data-protection-tips' },
          { id: 'dataProTraining', name: 'Data Proctection Trainings', route: '/admin/data-protection-training' },
          { id: 'bannerDataProtection', name: 'Data Proctection Banner', route: '/admin/data-protection-banner' },
          { id: 'organizationStructure', name: 'Organization Structure ', route: '/admin/organization-structure' },
          { id: 'dataProtectionGoodReads', name: 'Good Reads', route: '/admin/data-protection-good-reads' },
          { id: 'dataProtectionUserActivities', name: 'User Activty', route: '/admin/data-protection-user-activity' },
          { id: 'users-page-activites', name: 'User Page Activity', route: '/admin/users-page-activites' }, 
        ],
      },
    ];
  } else {

    
    pages = [
      {
        id: 'activity',
        name: 'All Activites',
        route: '/admin/all-activites',
        icon: <AssessmentIcon sx={{ color: '#fff' }} />,
      },
      {
        id: 'composeEmail',
        name: 'Send Phishing Email',
        route: '/admin/send-email',
        icon: <MarkEmailUnreadIcon sx={{ color: '#fff' }} />,
      },
      {
        id: 'emailTemplates',
        name: 'Email Templates',
        route: '/admin/email-templates',
        icon: <MarkEmailUnreadIcon sx={{ color: '#fff' }} />,
      },
      // {
      //   id: 'dashboard',
      //   name: 'Dashboard',
      //   route: '/admin',
      //   icon: <AssessmentIcon sx={{ color: '#fff' }} />,
      // },

      {
        id: 'Add Calendar',
        name: 'Add Calendar',
        icon: <CalendarMonthIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'Calender Years', name: 'Calender Years', route: '/admin/calendar/years' },
          { id: 'Calender Months', name: 'Calender Months', route: '/admin/calendar/months' },
          { id: 'Calender Weeks', name: 'Calender Weeks', route: '/admin/calendar/weeks' },
        ],
      },

      {
        id: 'Quiz',
        name: 'Quiz Contests',
        icon: <QuizIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'allQuiz', name: 'Quizs', route: '/admin/quiz' },
          { id: 'allQuestions', name: 'Questions', route: '/admin/questions' },
          { id: 'options', name: 'Options', route: '/admin/options' },
          { id: 'quizResults', name: 'Quiz Results', route: '/admin/quiz-results' },
        ],
      },

      {
        id: 'games',
        name: 'Games',
        route: '/admin/games',
        icon: <SmartToyIcon sx={{ color: '#fff' }} />,
      },

      {
        id: 'goodReads',
        name: 'Good Reads',
        icon: <NewspaperIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'articles', name: 'Articles', route: '/admin/goodreads' },
          { id: 'videos', name: 'Suggested Videos', route: '/admin/suggested-videos' },
        ],
      },

      {
        id: 'gisp',
        name: 'GISP & Resources',
        icon: <SecurityIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'policy', name: 'GISP Policy', route: '/admin/gisp-policy' },
          { id: 'ethics', name: 'Digital Ethics', route: '/admin/digital-ethics' },
          { id: 'quickTools', name: 'Quick Tools', route: '/admin/quick-tools' },
        ],
      },

      {
        id: 'dataProtection',
        name: 'Data Protection',
        icon: <SecurityIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'dataProTips', name: 'Data Proctection Tips', route: '/admin/data-protection-tips' },
          { id: 'dataProTraining', name: 'Data Proctection Trainings', route: '/admin/data-protection-training' },
          { id: 'bannerDataProtection', name: 'Data Proctection Banner', route: '/admin/data-protection-banner' },
          { id: 'organizationStructure', name: 'Organization Structure ', route: '/admin/organization-structure' },
          { id: 'dataProtectionGoodReads', name: 'Good Reads', route: '/admin/data-protection-good-reads' },
          { id: 'dataProtectionUserActivities', name: 'User Activty', route: '/admin/data-protection-user-activity' },
          { id: 'users-page-activites', name: 'User Page Activity', route: '/admin/users-page-activites' }, 
        ],
      },

      {
        id: 'banners',
        name: 'Banners',
        route: '/admin/banners',
        icon: <ImageIcon sx={{ color: '#fff' }} />,
      },

      {
        id: 'querty-in-veder',
        name: 'QWERTY IN_VADER',
        route: '/admin/querty-in-veder',
        icon: <ImageIcon sx={{ color: '#fff' }} />,
      },

      {
        id: 'opps-email',
        name: 'Oops Email',
        route: '/admin/opps-email',
        icon: <SmartToyIcon sx={{ color: '#fff' }} />,
      },
      {
        id: 'Oops Survey',
        name: 'Oops Survey',
        route: '/admin/servey',
        icon: <NewspaperIcon sx={{ color: '#fff' }} />,
      },
      {
        id: 'User Activities',
        name: 'user activities',
        route: '/admin/user-activities',
        icon: <ManageAccountsIcon sx={{ color: '#fff' }} />,
      },
      {
        id: 'october',
        name: 'October Month',
        icon: <SecurityIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'bannersoct', name: 'October Banners', route: '/admin/october-banners' },
          { id: 'octWeek', name: 'October Weeks', route: '/admin/october-weeks' },
          { id: 'octEvent', name: 'October Events', route: '/admin/october-events' },
          { id: 'octContest', name: 'October Contests', route: '/admin/october-contests' },
          { id: 'gamesOct', name: 'October Games', route: '/admin/october-games' },
          { id: 'whyHowWhat', name: 'What Why How', route: '/admin/what-why-how' },
          { id: 'octPledgeVideo', name: 'October Pledge video', route: '/admin/october-pledge-video' },
          { id: 'octWebinars', name: 'October Webinars', route: '/admin/october-webinar' },
          { id: 'octGallary', name: 'October Gallary', route: '/admin/october-gallary' },
          { id: 'octGuidelines', name: 'Contest Guidelines', route: '/admin/october-contest-guidelines' },
        ],
      },

      {
        id: 'octoberContests',
        name: 'October Contests',
        icon: <SecurityIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'phisingcontest', name: 'Phising Contest', route: '/admin/phising-contest' },
          { id: 'memeContest', name: 'Meme/Photo Contest', route: '/admin/meme-photo-contest' },
        ],
      },

      {
        id: 'trainingData',
        name: 'Cyber Trainings',
        icon: <PieChartIcon sx={{ color: '#fff' }} />,
        subpages: [
          { id: 'allCompletedData', name: 'Completed Trainings', route: '/admin/training-completed-data' },
          { id: 'withOutSavyantTrainings', name: 'Without saviynt Trainings', route: '/admin/training-completed-without-saviyant-data' },
          { id: 'updateTraningStatus', name: 'Update Trainings Status', route: '/admin/update-training-status' },
        ],
      },
      
      {
        id: 'visit-website',
        name: 'Visit Website',
        route: '/',
        icon: <PresentToAllIcon sx={{ color: '#fff' }} />,
      },
    ];
  }
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const location = useLocation();

  const handleSubMenuClick = (pageId: string) => {
    setOpenSubMenu((prev) => (prev === pageId ? null : pageId));
  };

  useEffect(() => {
    const activePage = pages.find((page) =>
      page.subpages?.some((subpage) => location.pathname.startsWith(subpage.route)),
    );
    if (activePage) {
      setOpenSubMenu(activePage.id);
    }
  }, [location]);

  return (
    <>
      {pages.map((page) => (
        <Box key={page.id}>
          {page.subpages ? (
            <>
              <ListItemButton
                className={`sidebarSubmenu ${openSubMenu === page.id ? 'subMenuOpen' : ''}`}
                onClick={() => handleSubMenuClick(page.id)}
                sx={{ color: '#fff' }}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.name} />
                <ListItemIcon sx={{ justifyContent: 'end' }}>
                  {openSubMenu === page.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemIcon>
              </ListItemButton>

              <Collapse in={openSubMenu === page.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.subpages.map((subpage) => (
                    <ListItemButton
                      className={`sub-menu-item ${location.pathname === subpage.route ? 'activeNavLink' : ''}`}
                      key={subpage.id}
                      component={Link}
                      to={subpage.route}
                      sx={{ pl: 4, color: '#fff' }}
                    >
                      <ListItemText primary={subpage.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemButton
              className={location.pathname === page.route ? 'activeMenu' : ''}
              component={page.route ? Link : 'div'}
              to={page.route}
              sx={{ color: '#fff' }}
            >
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItemButton>
          )}
        </Box>
      ))}
    </>
  );
};

export default MainListItems;
